<template>
  <div class="add-practitioner">
    <Stepper :isPrevStepRequired="true" :step="step" :texts="steps" />
    <v-row v-if="step === 1" class="pa-2">
      <v-col class="header" cols="12">
        <h4 class="title-text">Personal Information is required to help doctor make better diagnostic</h4>
        <p class="subtitle-text">
          We take confidential data seriously. Only a doctor will receive this information for a more personalized
          treatment.
        </p>
      </v-col>
    </v-row>
    <v-row v-if="step === 1" class="form pa-2">
      <v-col class="ps_input-group" cols="12">
        <h6 class="title-text">Complete Required Information</h6>
      </v-col>
      <v-col cols="6">
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.firstName") }}
          <span class="star-required">*</span>
        </span>
        <BaseInput
          v-model="firstName"
          :errors="firstNameErrors"
          fieldName="firstName"
          placeholder="First Name"
          @blur="$v.firstName.$touch()"
        />
      </v-col>
      <v-col cols="6">
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.lastName") }}
          <span class="star-required">*</span>
        </span>
        <BaseInput
          v-model="lastName"
          :errors="lastNameErrors"
          fieldName="lastName"
          placeholder="Last Name"
          @blur="$v.lastName.$touch()"
        />
      </v-col>
      <v-col cols="12">
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.dateOfBirth") }}
          <span class="star-required">*</span>
        </span>
        <DatePicker
          v-model="birthDate"
          :dense="true"
          :errors="dateOfBirthErrors"
          :height="47"
          :maxDate="new Date().toISOString().substr(0, 10)"
          :placeholder="$t('general.inputs.dateOfBirth')"
          hide-details
          @blur="$v.birthDate.$touch()"
          @setDate="onChangeDate"
        />
        <span class="heading-5 text-left d-block pb-2 pt-3">
          Gender
          <span class="star-required">*</span>
        </span>
        <Switcher v-model="gender" :items="$t('general.genders')" class-name="mb-4 " @change="onGenderChange" />
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.address") }}
        </span>
        <BaseInput
          v-model="address"
          :placeholder="$t('general.inputs.address')"
          class="mb-4"
          fieldName="address"
          @change="onInputChange"
        />
        <span class="heading-5 text-left d-block pb-2">
          Zip Code
          <span class="star-required">*</span>
        </span>
        <BaseInput
          v-model="postalCode"
          :errors="postalCodeErrors"
          class="mb-4"
          fieldName="postalCode"
          placeholder="Zip Code"
          @blur="$v.postalCode.$touch()"
        />
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.city") }}
          <span class="star-required">*</span>
        </span>
        <v-autocomplete
          :errors="cityErrors"
          :items="predictionsValue"
          :label="$t('general.inputs.city')"
          :loading="isLoadingPredictions"
          :search-input.sync="predictionsSearch"
          :value="city"
          class="bd-grey mb-4 autocomplete"
          clearable
          fieldName="city"
          hide-details
          hide-no-data
          placeholder="Enter city"
          solo
          @change="onSelectPredictions"
        ></v-autocomplete>
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.state") }}
          <span class="star-required">*</span>
        </span>
        <StateSelect v-model="state" class="mb-4" @blur="$v.state.$touch()" @change="stateSelectChange" />
        <span class="heading-5 text-left d-block pb-2">
          Last 4 digits of Social Security Number
          <span class="star-required">*</span>
        </span>
        <BaseInput
          v-model="socialSecurityNumber"
          :errors="socialSecurityNumberErrors"
          class="mb-4"
          fieldName="socialSecurityNumber"
          maskPattern="xxxx-xx-####"
          placeholder="Last 4 digits of Social Security Number"
          @blur="$v.socialSecurityNumber.$touch()"
        />
        <span class="heading-5 text-left d-block pb-2"> Home phone number </span>
        <PhoneInput
          v-model="homePhoneNumber"
          class="mb-4"
          fieldName="homePhoneNumber"
          placeholder="Home phone number"
          @change="onHomePhoneNumberChange"
        />
        <span class="heading-5 text-left d-block pb-2">
          Mobile phone number
          <span class="star-required">*</span>
        </span>
        <PhoneInput
          v-model="phoneNumber"
          :errors="phoneNumberErrors"
          class-name="mb-4"
          fieldName="phoneNumber"
          placeholder="Mobile phone number"
          @blur="$v.phoneNumber.$touch()"
          @change="onPhoneNumberChange"
        />
        <span class="heading-5 text-left d-block pb-2"> Email </span>
        <BaseInput v-model="email" class="mb-4" fieldName="email" placeholder="Email" />
      </v-col>
      <v-col cols="12">
        <PrimaryButton
          :disabled="$v.$invalid"
          :full-width="true"
          :loading="buttonLoading"
          text="Finish Patient Account Creating"
          @onClick="saveChanges"
        />
      </v-col>
    </v-row>
    <v-row v-if="step === 2" class="step-2 pa-2">
      <v-col class="header" cols="12">
        <h4 class="title-text green--text">Congratulations!</h4>
        <h4 class="title-text">Patient have been registered in the system</h4>
        <p class="subtitle-text">Now email with generated password was sent to Practitioner.</p>
        <p class="subtitle-text">
          To add another Patient
          <span class="link pointer" @click="onNew">click here</span>
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { required } from "vuelidate/lib/validators";

import { PatientsApi } from "@/api/patients";
import DatePicker from "@/components/shared/DatePicker";
import PhoneInput from "@/components/shared/PhoneInput";
import StateSelect from "@/components/shared/StateSelect";
import Stepper from "@/components/shared/Stepper";
import BaseInput from "@/components/uikit/BaseInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Switcher from "@/components/uikit/Switcher";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { validatorErrors } from "@/mixins/validatorErrors";
import { defaultLocale } from "@/utils/constants";
import { isValidPhoneNumber, isValidZip } from "@/utils/validations";

const googleAutocompleteService = new window.google.maps.places.AutocompleteService();

export default {
  name: "RegisterPatient",
  components: { PhoneInput, Switcher, DatePicker, Stepper, StateSelect, PrimaryButton, BaseInput },
  data: () => {
    return {
      step: 1,
      gender: "other",
      socialSecurityNumber: "",
      buttonLoading: false,
      isLoadingPredictions: false,
      email: "",
      firstName: "",
      qualification: "",
      address: "",
      state: "",
      predictionsSearch: "",
      city: "",
      postalCode: "",
      predictions: [],
      lastName: "",
      locale: defaultLocale,
      loading: false,
      birthDate: "",
      homePhoneNumber: "",
      phoneNumber: "",
    };
  },
  mixins: [validatorErrors],
  watch: {
    predictionsSearch(val) {
      if (!val || val === this.name) return;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.isLoadingDrugs = true;
        const displaySuggestions = (predictions, status) => {
          if (status != window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
            return;
          }
          this.predictionsResults = predictions;
          this.predictions = predictions.map((pr) => pr?.terms?.[0].value || "");
        };
        googleAutocompleteService.getPlacePredictions({ input: val, types: ["(cities)"] }, displaySuggestions);
      }, 300);
    },
  },
  validations: {
    locale: { required },
    firstName: { required },
    lastName: { required },
    socialSecurityNumber: { required },
    birthDate: { required },
    phoneNumber: { required, isValidPhoneNumber },
    city: { required },
    state: { required },
    postalCode: { required, isValidZip },
  },
  computed: {
    predictionsValue() {
      return this.city ? [...this.predictions, this.city] : this.predictions;
    },
    socialSecurityNumberErrors() {
      const errors = [];
      if (!this.$v.socialSecurityNumber.$dirty) return errors;
      !this.$v.socialSecurityNumber.required &&
        errors.push(this.$t("validation.required", { field: "Social security number" }));
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      (!this.$v.phoneNumber.required || !this.$v.phoneNumber.isValidPhoneNumber) &&
        errors.push(this.$t("validation.required", { field: "Mobile phone number" }));
      return errors;
    },
    dateOfBirthErrors() {
      const errors = [];
      if (!this.$v.birthDate.$dirty) return errors;
      !this.$v.birthDate.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.dateOfBirth") }));
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push(this.$t("validation.required", { field: this.$t("general.inputs.city") }));
      return errors;
    },
    postalCodeErrors() {
      const errors = [];
      if (!this.$v.postalCode.$dirty) return errors;
      (!this.$v.postalCode.required || !this.$v.postalCode.isValidZip) &&
        errors.push(this.$t("validation.required", { field: "Zip Code" }));
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required &&
        errors.push(this.$t("validation.required", { input: this.$t("general.inputs.state") }));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("validation.email"));
      !this.$v.email.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.firstName") }));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.lastName") }));
      return errors;
    },
    steps() {
      return {
        _1: "Complete Required Information",
        _2: "Create Patient account",
      };
    },
  },
  methods: {
    onInputChange(value, fieldName) {
      this[fieldName] = value;
    },
    onPhoneNumberChange(value, fieldName) {
      this[fieldName] = value;
    },
    onSelectPredictions(selectedValue) {
      const item = this.predictionsResults.find((item) => item?.terms?.[0].value === selectedValue);
      if (item) {
        this.city = item?.terms?.[0].value || "";
      }
    },
    onHomePhoneNumberChange(value, fieldName) {
      this[fieldName] = value;
      this.$v.homePhoneNumber.$touch();
    },
    goToPage(number) {
      this.step = number;
    },
    onChangeDate(e) {
      this.birthDate = e;
    },
    onGenderChange(gender) {
      this.gender = gender;
    },
    onNew() {
      this.$v.$reset();
      this.step = 1;
      this.buttonLoading = false;
      this.city = "";
      this.address = "";
      this.postalCode = "";
      this.email = "";
      this.firstName = "";
      this.state = "";
      this.lastName = "";
      this.locale = defaultLocale;
      this.loading = false;
    },
    stateSelectChange(state) {
      this.state = state;
    },
    async saveChanges() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.buttonLoading = true;
        const body = {
          email: this.email,
          address: this.address,
          city: this.city,
          postalCode: this.postalCode,
          firstName: this.firstName,
          lastName: this.lastName,
          locale: this.locale,
          birthDate: moment(this.birthDate).utc(true).format("YYYY-MM-DD"),
          socialSecurityNumber: this.socialSecurityNumber.split("xxx-xx-")[1],
          homePhoneNumber: this.homePhoneNumber,
          phoneNumber: this.phoneNumber,
          state: this.state,
        };
        await PatientsApi.registerNewPatient(body);
        this.step = 2;
      } catch (err) {
        console.error(err);
        if (err?.message?.length && !err?.message.map)
          snackBarEventBus.$emit(snackBarEventName, {
            message: (err && err.message) || err,
            type: "error",
          });
        else
          snackBarEventBus.$emit(snackBarEventName, {
            message: "Validation failed!",
            type: "error",
          });
      } finally {
        this.buttonLoading = false;
      }
      // this.buttonLoading = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-practitioner {
  background-color: white;

  .header {
    margin: 0 auto;

    margin-top: 72px;
    justify-content: center;
    max-width: 680px;
    @include mobile {
      margin-top: 48px;
    }

    .title-text {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #33343e;
      @include mobile {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .subtitle-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.32px;

      &:first-of-type {
        padding-top: 15px;
      }
    }
  }

  .form {
    max-width: 464px;
    background: #ffffff;
    border: 1px solid #e7e8f2;
    border-radius: 6px;
    margin: 36px auto 0;

    .title-text {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #33343e;
    }
  }

  .step-2 {
    .header {
      .title-text {
        font-size: 28px;
        line-height: 34px;
      }

      .subtitle-text {
        margin: 0 auto;
        max-width: 500px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.32px;
      }
    }
  }
}
</style>
